<template>
	<div class="attendance">
		<div>
			<van-calendar
				:poppable="false"
				:min-date="minDate"
				:max-date="maxDate"
				type="single"
				:color="defineStyle.themeColor"
				:date="date"
				@select="GetByDateTime"
				:show-confirm="false"
				:style="{ height: '100%' }"
				:show-mark="false"
				row-height="45"
			>
				<template #title>
					<div class="l-header">
						<div>
							<i class="iconfont icon-arrow02" @click="$router.go(-1)"></i>
						</div>
						<div></div>
						<div></div>
					</div>
				</template>
			</van-calendar>
		</div>
		<div class="person-info">
			<div>{{ dayjs(date).format('YYYY') }}</div>
			<div>
				<div>
					<van-image width="48" height="48" :src="userPhoto" />
				</div>
				<div>
					<div>
						<span>{{ records.RealName }}</span>
						<i class="iconfont icon-yuangong-ren"></i>
					</div>
					<div>
						体温{{ records.Temperature > 0 ? records.Temperature : '"未检测"' }}℃正常
					</div>
				</div>
			</div>
			<div>
				<div>
					<div>{{ workTime }}</div>
					<div>
						<div>上班</div>
						<div :class="workshow.classStyle" v-text="workshow.text"></div>
					</div>
				</div>
				<div>
					<div>{{ lowerWorkTime }}</div>
					<div>
						<div>下班</div>
						<div :class="xworkshow.classStyle" v-text="xworkshow.text"></div>

						<!-- <div>下班</div>
            <div>下班打卡(18:55)</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Calendar, CountDown, Image as VanImage } from 'vant'
import {
	GetByUserIdAttendanceRules,
	GetByUserIdAttendancerecords,
} from '@/api/Attendance/AttendanceRecords.js'
import defineMethods from '@/methods'
export default {
	components: {
		[Calendar.name]: Calendar,
		[VanImage.name]: VanImage,
	},
	data() {
		return {
			date: new Date(),
			userId: JSON.parse(defineMethods.getCookie('LoginUserInfo')).id,
			//园区Id
			gardenId: JSON.parse(defineMethods.getCookie('LoginUserInfo')).GardenId,
			minDate: new Date(2020, 0, 1),
			maxDate: new Date(2022, 0, 31),
			//判断是否有考勤规则
			isAttendance: '',
			//上班打卡时间
			workTime: '',
			//下班打卡时间
			lowerWorkTime: '',
			//考勤记录
			records: {},
			//用户头像
			userPhoto: "require('@/assets/gentleman.png')",

			//上班打卡
			workshow: {
				classStyle: '',
				text: '',
			},
			//下班打卡
			xworkshow: {
				classStyle: '',
				text: '',
			},
			//上班状态
			SworkStates: {
				SworkStateText: '',
				SworkStateClass: '',
			},
			//下班状态文本
			SworkStatex: {
				XworkStateText: '',
				XworkStateClass: '',
			},
		}
	},
	methods: {
		//初始化考勤规则
		async AttendanceRulesLoad() {
			let reqdate = await GetByUserIdAttendanceRules(this.userId, this.gardenId)

			let data = reqdate.Data

			if (defineMethods.isNullOrEmpty(data)) {
				this.isAttendance = 'false'
				this.$toast('您没有考勤规则')
				return
			}
			let timeRulesJSON = JSON.parse(data.TimeRulesJSON)
			//上班打卡时间
			this.workTime = timeRulesJSON[0].a.substring(0, timeRulesJSON[0].a.length - 3)

			//下班打卡时间
			this.lowerWorkTime = timeRulesJSON[0].d.substring(0, timeRulesJSON[0].d.length - 3)
			this.isAttendance = 'true'
		},

		//考勤记录
		async Attendancerecords(datetime) {
			//this.userId
			let reqdate = await GetByUserIdAttendancerecords(this.userId, datetime)

			console.log(reqdate.Data)

			if (reqdate.ResultCode == 206) {
				this.workshow = {
					classStyle: 'warnning',
					text: '未打卡',
				}
				this.xworkshow = {
					classStyle: 'warnning',
					text: '未打卡',
				}

				this.$toast('当天没有考勤记录')
			} else {
				if (!defineMethods.isNullOrEmpty(reqdate.Data.UserPhoto)) {
					this.userPhoto = reqdate.Data.UserPhoto
				}

				this.records = reqdate.Data

				//上班状态
				this.SworkStates.SworkStateText =
					reqdate.Data.MorningStatus == 0
						? '正常打卡'
						: reqdate.Data.MorningStatus == 1
						? '迟到打卡'
						: reqdate.Data.MorningStatus == 2
						? '早退打卡'
						: reqdate.Data.MorningStatus == 3
						? '未打卡'
						: '补卡'

				let datetimes = this.dayjs.unix(this.records.MorningTime).format('HH:mm')

				this.SworkStates.SworkStateClass = reqdate.Data.MorningStatus == 0 ? '' : 'warnning'

				if (reqdate.Data.MorningStatus == 3) {
					this.workshow = {
						classStyle: this.SworkStates.SworkStateClass,
						text: this.SworkStates.SworkStateText,
					}
				} else {
					this.workshow = {
						classStyle: this.SworkStates.SworkStateClass,
						text: '' + this.SworkStates.SworkStateText + '(' + datetimes + ')',
					}
				}

				//下班班状态
				this.SworkStatex.XworkStateText =
					reqdate.Data.AfternoonStatus == 0
						? '正常打卡'
						: reqdate.Data.AfternoonStatus == 1
						? '迟到打卡'
						: reqdate.Data.AfternoonStatus == 2
						? '早退打卡'
						: reqdate.Data.AfternoonStatus == 3
						? '未打卡'
						: '补卡'

				let datetimex = this.dayjs.unix(this.records.AfternoonTime).format('HH:mm')

				this.SworkStatex.XworkStateClass =
					reqdate.Data.AfternoonStatus == 0 ? '' : 'warnning'

				if (reqdate.Data.AfternoonStatus == 3) {
					this.xworkshow = {
						classStyle: this.SworkStatex.XworkStateClass,
						text: this.SworkStatex.XworkStateText,
					}
				} else {
					this.xworkshow = {
						classStyle: this.SworkStatex.XworkStateClass,
						text: '' + this.SworkStatex.XworkStateText + '(' + datetimex + ')',
					}
				}
			}
		},
		//单击日期
		GetByDateTime(data) {
			this.SworkStatex.XworkStateText = ''
			this.SworkStatex.SworkStateText = ''
			let time = this.dayjs(this.dayjs(data).format('YYYY-MM-DD')).unix()
			//考勤记录
			this.Attendancerecords(time)
		},
	},
	computed: {},
	mounted() {
		//考勤规则
		this.AttendanceRulesLoad()
	},
	watch: {
		isAttendance() {
			// if (this.isAttendance == "false") {
			//   return;
			// }

			//当前时间戳
			let datetime = this.dayjs().unix()

			//考勤记录
			this.Attendancerecords(datetime)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.attendance {
	width: 100%;
	height: 100%;
	background: $bgColor;
	> div:first-child {
		width: 100%;
		height: 340px;
		margin-bottom: 4px;
		.l-header {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			margin: 0 16px;
			padding: 16px 0;
			position: absolute;
			top: -15px;
			> div:first-child {
				color: $titleColor;
				> i {
					font-size: 20px;
				}
			}
			> div:nth-child(2) {
				font-size: 16px;
				font-weight: 400;
				color: $titleColor;
				margin-left: -15px;
			}
		}
	}
	> div:nth-child(2) {
		width: 100%;
		height: 280px;
	}
	.person-info {
		background: #fff;
		padding: 0 16px;
		> div:first-child {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			color: #2a2a2a;
			padding: 16px 0;
		}
		> div:nth-child(2) {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			> div:first-child {
				border-radius: 8px;
				display: inline-block;
				margin-right: 10px;
			}
			> div:nth-child(2) {
				font-size: 12px;
				font-weight: 400;
				color: #7f7f7f;
				> div:last-child {
					margin-top: 3px;
				}
				span {
					font-size: 16px;
					font-weight: 400;
					color: $titleColor;
				}
				span + i {
					margin-left: 4px;
					color: $gray;
				}
			}
		}
		> div:nth-child(3) {
			margin-top: 21px;
			> div:first-child {
				> div:first-child::before {
					content: '';
					width: 3px;
					height: 150%;
					position: absolute;
					top: 25px;
					left: 30%;
					background: #e5e5e5;
				}
			}
			> div {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				> div:first-child {
					width: 85px;
					font-size: 18px;
					font-weight: 500;
					color: $titleColor;
					position: relative;
				}

				> div:last-child {
					flex: 1;
					padding: 14px 0;
					> div:first-child {
						font-size: 14px;
						font-weight: 500;
						line-height: 24px;
						color: $titleColor;
					}
					> div:nth-child(2).warnning {
						color: $red;
					}
					> div:last-child {
						font-size: 12px;
						font-weight: 400;
						color: $titleColor;
					}
				}
			}
			> div:first-child {
				> div:last-child {
					border-bottom: 1px solid #f4f4f5;
				}
			}
		}
	}
}
</style>
<style lang="scss">
@import '../../style/public.scss';
.attendance {
	.van-calendar__selected-day {
		border-radius: 50%;
		width: 28px !important;
		height: 28px !important;
	}
	.van-calendar__day {
		font-size: 12px;
	}
	.van-calendar__header-title {
		height: 0;
	}
	.van-calendar__header-subtitle {
		border-bottom: 1px solid rgba(244, 244, 245, 0.8);
	}
	.van-calendar__header {
		box-shadow: none;
	}
}
.workState {
	padding-left: 20px;
	color: green;
}
</style>
