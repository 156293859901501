
//考勤记录API

import service from "../config.js";
import link from "../link.js";

let ApiUrl = link.IOtApiChen.APIService;

//根据用户Id查询该用户的考勤规则
export const GetByUserIdAttendanceRules = async (userid, gardenId) => {
  let responseData = await service({
    url: ApiUrl + "/api/AttendanceRecords/GetByUserIdAttendanceRules",
    method: "get",
    params: {
      "userid": userid,
      "GardenId": gardenId
    }
  })
  return responseData;
}

//根据用户Id查询用户考勤记录
export const GetByUserIdAttendancerecords = async (userid, datetime) => {

  let responseData = await service({
    url: ApiUrl + "/api/AttendanceRecords/GetByUserIdAttendancerecords",
    method: "get",
    params: {
      "userid": userid,
      "datetime": datetime
    }
  })

  return responseData;
}